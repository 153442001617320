nav {
    background-color: $clr-accent-400;
    
    .logo {
        height: 2.5rem;
        position: relative;
        top: 0;
        left: 0;

        @include for-desktop {
            height: 3.5rem;
        }
    }
}

.navbar-brand {
    display: none;

    @include for-desktop {
        display: inline;
    }
}

.navbar {
    // display: none;
    padding-top: 0;
    padding-bottom: 0;

    @include for-desktop {
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .nav-link {
        color: $clr-accent-200 !important;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: .75em;

        @include for-desktop {
            font-size: 1rem;
        }

        &:hover, &:focus {
            color: $clr-primary-400;
        }
    }
}