.menu--section1,
.menu--section2 {
    padding-top: 1em;
    padding-bottom: 2em;

    h2 {
        font-size: 1.5rem;
    }
}

.split {
    display: flex;
    flex-direction: column;

    @include for-desktop {
        flex-direction: row;

        >* {
            flex-basis: 100%;
        }

        >*+* {
            margin-left: 2em;
        }
    }
}


.our-menu {
    header {
        .container {
            margin-top: 7.25em;

            p {
                text-transform: uppercase;
                font-family: $ff-accent;
                font-size: 2rem;
                margin: 0;

            }
        }
    }
}

.menu {

    .item-container {
        display: flex;
        flex-wrap: nowrap;

        .item {
            flex: 70%;
            margin-left: .25em;

            h3 {
                font-size: 1rem;

                @include for-desktop {
                    font-size: 1.25rem;
                }
            }

            h4 {
                margin: 0;
                font-size: .875rem;
                color: $clr-primary-800;

                @include for-desktop {
                    font-size: 1rem;
                }
            }

            p {
                font-size: .75rem;
                line-height: 1;
                font-style: oblique;
            }
        }

        .price {
            width: 10%;
            align-items: flex-end;
            margin-right: .25em;

            span {
                display: block;
                font-size: .75rem;
                font-weight: bold;
                text-align: right;

                @include for-desktop {
                    font-size: 1rem;
                }
            }
            
            .size {
                color: $clr-accent-400;
            }
        }
    }
}