#home {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;

    .bg-hero {
        width: 100%;
        height: 100vh;
        background-image:
        linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
        url(../images/coffee-bg-mobile.jpg);
        background-size: cover;
        background-position: center;
        color: $clr-neutral-100;

        @include for-desktop {

            background-image:
            linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),
            url(../images/coffee-bg.jpg);

        }

        h1 {
            font-size: 2.75rem;
            text-shadow: 2px 2px $clr-primary-200;

            @include for-desktop {
                font-size: 4.5rem;
            }
        }
    }

    .content-area {
        position: absolute;
        top: 40%;
        bottom: 60%;
        left: 0;
        right: 0;
    }

    .h3 {
        color: $clr-primary-400;
    }

    .btn {
        margin-top: 20px;
    }

}