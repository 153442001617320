#our-brand {
    background-color: $clr-primary-200;
    color: $clr-neutral-600;
    background-image: url('/assets/images/map-1.png');
    background-repeat: no-repeat;
    background-position: 80% 50%;
    background-size: cover;

    @include for-desktop {
        background-position: 50% 50%;
        background-size: cover;

    }

    p {
        font-size: 1.25rem;
    }

}