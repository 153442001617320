#contact {
    .contact-address {
        font-size: .875rem;
    }
    .hours {
        padding-top: 25px;
        padding-bottom: 25px;

        h5 {
            @include for-desktop {
                font-size: .875rem;

            }
        }
    }
}

.map {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}