footer {
    .nav-link {
        padding: 0;
    }
}

.footer-top {
    background-image: url(/assets/images/footertile-1.jpg);
    background-repeat: repeat;

    .logo-text {
        color: $clr-accent-400;
        font-size: 1.2rem;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        padding-left: 0;
    }
}

.footer-bottom {
    background-color: $clr-primary-800;
}

footer .footer-links a,
footer .social-icons a {
   color: $clr-accent-200;
   text-transform: uppercase;
   font-size: .875rem;
}

footer .social-icons a:hover,
footer .footer-links a:hover {
   color: $clr-neutral-600;
}

footer .social-icons a {
   font-size: 24px;
}

footer .footer-links a {
   display: block;
   font-weight: 400;
}

.footer-bottom {
    font-size: .75rem;

    a {
        color: $clr-accent-400;

        &:hover {
            color: $clr-neutral-600;
        }
    }
}