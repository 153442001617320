html {
    font-size: 16px;

    @include for-desktop {
        font-size: 18px;
    }
}

body {
    line-height: 1.8;
    color: $clr-primary-400;
    font-size: 1rem;
    font-family: $ff-primary;
}

// main > div {
//   height: 10rem;
//   margin: 2rem;
//   border: darkgrey 1px solid;
//   border-radius: 8px;
//   overflow: hidden;
// }

h1,h2,h3,h4,h5,h6 {
   font-weight: 700;
   font-family: $ff-accent;
   color: $clr-accent-400;
   letter-spacing: 1px;
   text-transform: uppercase;
}

h1 {
  text-align: center;
}

section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include for-desktop {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}


a {
   font-weight: 700;
   transition: all 0.4s ease;
   text-decoration: none;
}

img {
   width: 100%;
}

small {
   font-size: 12px;
   letter-spacing: 2px;
   font-weight: 700;
   text-transform: uppercase;
}

.btn {
   font-weight: 700;
   font-size: 12px;
   text-transform: uppercase;
   padding: 12px 28px;
   border-radius: 0;
   background-color: $clr-accent-400;
   color: #fff;
}

:not(.btn-check) + 
.btn:hover, .btn:first-child:hover {
   background-color: $clr-neutral-600;
   color: #fff;
   transition: .9s;
}

.section-intro {
    text-align: center;
    margin-bottom: 2.75rem;
}

.section-intro {
  .hline {
    width: 120px;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 16px auto 16px auto;
  }
}

.icon-box {
   width: 60px;
   height: 60px;
   background-color: #E93B81;
   color: #fff;
   border-radius: 4px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 32px;
   flex: none;
}

.title-sm {
   font-size: 1rem;
   letter-spacing: 1px;
}