@import url('https://fonts.googleapis.com/css2?family=Chau+Philomene+One:ital@0;1&family=Poppins:wght@100;200;300;600;700&display=swap');

/* Colors ----> */

$clr-primary-200: #e9e3da;
$clr-primary-400: #c9c0b5;
$clr-primary-600: #574e43;
$clr-primary-800: #3f362f;
$clr-primary-900: #444444;

$clr-neutral-100: #fcfcfc;
$clr-neutral-600: #877768;

$clr-accent-200: #e2dacd;
$clr-accent-400: #b73827;


/* fonts ---> */

$ff-primary: 'Poppins', Helvetica, Arial, sans-serif;
$ff-accent: 'Chau Philomene One', sans-serif;
$ff-content: 'Times New Roman', serif;